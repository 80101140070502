import React from 'react';
import cn from 'classnames';
import PinIcon from '@/../public/assets/img/geoPlacemark.svg';
import PinIconActive from '@/../public/assets/img/geoPlacemarkActive.svg';
import TopShop from '@/../public/assets/img/topShop.svg';
import ActiveTopShop from '@/../public/assets/img/activeTopShop.svg';
import styles from './map.module.scss';
interface PlacemarkProps {
  isActive?: boolean;
  isTopShop?: boolean;
}

export const Placemark = ({ isActive, isTopShop }: PlacemarkProps) => {
  const topShop = isActive ? <ActiveTopShop /> : <TopShop />;
  const pinIcon =  isActive ? <PinIconActive /> : <PinIcon />;

  return (
    <div className={cn(styles.placemark, 'placemark')}>
      {isTopShop ? topShop : pinIcon}
    </div>
  )
};